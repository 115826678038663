@import '@we-make-websites/styles-lib/src/config/configuration';
/**
 * Styles: Menu drawer
 * -----------------------------------------------------------------------------
 * Base styles for menu drawer component.
 * - See global/overlay/menu-drawer-overlay.scss for critical styles.
 *
 */
.menuDrawer {
  $parent: &;

  --menu-gutter: var(--spacing-l);
  max-height: 100%;
  min-height: 100%;
  height: 1px;
  background-color: var(--color-background-light);
  width: 90vw;

  overflow: auto;

  &.criticalComponentHide {
    display: block;
  }

  &.criticalComponentClear {
    opacity: 1;
  }

  /**
   * Navigation.
   */
  &__navigation {
    flex: 1;
    height: fit-content;
    min-height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &::-webkit-scrollbar {
      display: none;
      width: 0;
    }
  }

  &__menus {
    margin: 0;
    overflow: hidden;
    position: relative;
    width: 100%;
  }

  /**
   * Menu (tier linklist).
   */
  &__menu#{$parent}__menu {
    @include transition(
      opacity var(--timing-quick),
      transform var(--timing-quick)
    );
    height: 100%;
    left: 0;
    list-style-type: none;
    margin: 0;
    top: 0;
    width: 100%;

    &.isActive {
      opacity: 1;
      pointer-events: auto;
      display: initial;
    }

    &.hasActiveSubMenu {
      display: initial;
    }
  }

  &__subTier {
    opacity: 1;
    pointer-events: none;
    transform: translateX(100%);
    display: none;
    position: absolute;
  }

  /**
   * Links.
   */
  &__link {
    align-items: center;
    color: var(--color-text-primary);
    display: flex;
    gap: var(--spacing-s);
    margin-block-end: 0;
    // Icon height + padding
    min-height: calc(var(--icon-m) + (var(--spacing-l) * 1.75));
    padding-block-end: var(--menu-gutter);
    padding-block-start: var(--menu-gutter);
    padding-inline-end: var(--spacing-l);
    padding-inline-start: var(--spacing-l);
    position: relative;
    text-align: left;
    text-decoration: none;
    text-transform: uppercase;
    width: 100%;
  }

  &__button {
    @include button-reset($font: false);
    padding-block-end: var(--spacing-m);
    padding-block-start: var(--spacing-m);
    padding-inline-end: var(--menu-gutter);
    padding-inline-start: var(--menu-gutter);
    width: 100%;

    :global {
      .icon {
        margin-inline-start: auto;
      }
    }
  }

  &__boldText {
    font-weight: 700 !important;
  }

  &__back {
    color: var(--color-mid-grey);
    gap: var(--spacing-m);
    justify-content: start;

    :global {
      .icon {
        margin-inline-start: calc(var(--spacing-xs) * -1);
      }
    }
  }

  &__subListContainer {
    margin-block-start: var(--spacing-xl);
  }

  &__BlockHeading,
  &__BlockBody {
    margin-block-end: 0;
    margin-block-start: 0;
    margin-inline-end: var(--menu-gutter);
    margin-inline-start: var(--menu-gutter);
    font-weight: 700 !important;
  }

  &__BlockBody {
    display: grid;
    gap: var(--spacing-2xl) var(--spacing-s);
    grid-template-columns: 1fr 1fr;
    padding-block-end: var(--spacing-l);
    padding-block-start: var(--spacing-2xl);
  }

  &__BlockHeading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 0;
    border-bottom: 1px solid var(--color-border-light);
    color: var(--color-secondary);
    margin-block-start: var(--spacing-xl);
    padding-block-start: var(--spacing-m);
    text-transform: uppercase;
  }

  &__BlockColumn {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-xs);
    text-decoration: none;
    max-width: 100%;
  }

  &__BlockColumnImage {
    @include aspect-ratio($force: true);
    // 1 / 1 aspect ratio
    --ratio-percent: 100%;
  }

  &__footer {
    background-color: var(--color-extra-light-grey);
    display: grid;
    width: 100%;
  }

  &__footerLinks {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-xl);
    padding-block-end: var(--spacing-m);
    padding-block-start: var(--spacing-m);
    padding-inline-end: var(--menu-gutter);
    padding-inline-start: var(--menu-gutter);
  }

  &__footerLink {
    align-items: center;
    display: flex;
    gap: var(--spacing-s);
    width: fit-content;
  }

  &__languageSwitcher {
    @include button-reset;
    width: fit-content;
  }

  &__footerSocials {
    padding-block-end: var(--spacing-m);
    padding-inline-end: var(--menu-gutter);
    padding-inline-start: var(--menu-gutter);
    padding-block-start: var(--spacing-m);
    border-top: 1px solid var(--color-light-grey);
  }

  /**
   * Set focus ring to be inset so it's not cropped.
   */
  a[href]#{$parent}__link:focus-visible,
  button:enabled#{$parent}__link:focus-visible,
  a[href]#{$parent}__BlockColumn:focus-visible,
  button:enabled#{$parent}__BlockColumn:focus-visible {
    @include focus-ring($inset: true);
  }

  @supports (-webkit-touch-callout: none) {
    height: -webkit-fill-available;

    &__navigation {
      min-height: -webkit-fill-available;
    }
  }
}

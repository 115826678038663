/**
 * Base: Classes
 * -----------------------------------------------------------------------------
 * Automatically generated by `design` command, do not edit.
 *
 */
// stylelint-disable

// Typography
html,
body {
  font-family: var(--font-family-sans);
  font-size: var(--scale-base);
  font-weight: var(--font-weight-regular);
  letter-spacing: var(--letter-spacing-normal);
  line-height: var(--line-height-body1);
  text-decoration: none;
}

.text-heading-xl {
  @include text-heading-xl;

  @include mq($from: l) {
    &-desktop.text-heading-xl-desktop {
      @include text-heading-xl;
    }
  }
}

.text-heading-h1 {
  @include text-heading-h1;

  @include mq($from: l) {
    &-desktop.text-heading-h1-desktop {
      @include text-heading-h1;
    }
  }
}

.text-heading-h2 {
  @include text-heading-h2;

  @include mq($from: l) {
    &-desktop.text-heading-h2-desktop {
      @include text-heading-h2;
    }
  }
}

.text-heading-h3 {
  @include text-heading-h3;

  @include mq($from: l) {
    &-desktop.text-heading-h3-desktop {
      @include text-heading-h3;
    }
  }
}

.text-heading-h4 {
  @include text-heading-h4;

  @include mq($from: l) {
    &-desktop.text-heading-h4-desktop {
      @include text-heading-h4;
    }
  }
}

.text-heading-h4-bold {
  @include text-heading-h4-bold;

  @include mq($from: l) {
    &-desktop.text-heading-h4-bold-desktop {
      @include text-heading-h4-bold;
    }
  }
}

.text-heading-h5 {
  @include text-heading-h5;

  @include mq($from: l) {
    &-desktop.text-heading-h5-desktop {
      @include text-heading-h5;
    }
  }
}

.text-heading-h6 {
  @include text-heading-h6;

  @include mq($from: l) {
    &-desktop.text-heading-h6-desktop {
      @include text-heading-h6;
    }
  }
}

.text-heading-h7 {
  @include text-heading-h7;

  @include mq($from: l) {
    &-desktop.text-heading-h7-desktop {
      @include text-heading-h7;
    }
  }
}

.text-heading-xl-mobile {
  @include text-heading-xl-mobile;

  @include mq($from: l) {
    &-desktop.text-heading-xl-mobile-desktop {
      @include text-heading-xl-mobile;
    }
  }
}

.text-heading-h1-mobile {
  @include text-heading-h1-mobile;

  @include mq($from: l) {
    &-desktop.text-heading-h1-mobile-desktop {
      @include text-heading-h1-mobile;
    }
  }
}

.text-heading-h2-mobile {
  @include text-heading-h2-mobile;

  @include mq($from: l) {
    &-desktop.text-heading-h2-mobile-desktop {
      @include text-heading-h2-mobile;
    }
  }
}

.text-heading-h3-mobile {
  @include text-heading-h3-mobile;

  @include mq($from: l) {
    &-desktop.text-heading-h3-mobile-desktop {
      @include text-heading-h3-mobile;
    }
  }
}

.text-heading-h4-mobile {
  @include text-heading-h4-mobile;

  @include mq($from: l) {
    &-desktop.text-heading-h4-mobile-desktop {
      @include text-heading-h4-mobile;
    }
  }
}

.text-heading-h5-mobile {
  @include text-heading-h5-mobile;

  @include mq($from: l) {
    &-desktop.text-heading-h5-mobile-desktop {
      @include text-heading-h5-mobile;
    }
  }
}

.text-heading-h6-mobile {
  @include text-heading-h6-mobile;

  @include mq($from: l) {
    &-desktop.text-heading-h6-mobile-desktop {
      @include text-heading-h6-mobile;
    }
  }
}

.text-heading-h7-mobile {
  @include text-heading-h7-mobile;

  @include mq($from: l) {
    &-desktop.text-heading-h7-mobile-desktop {
      @include text-heading-h7-mobile;
    }
  }
}

p,
.text-body-1 {
  @include text-body-1;

  @include mq($from: l) {
    &-desktop.text-body-1-desktop {
      @include text-body-1;
    }
  }
}

.text-body-2 {
  @include text-body-2;

  @include mq($from: l) {
    &-desktop.text-body-2-desktop {
      @include text-body-2;
    }
  }
}

.text-body-1-bold {
  @include text-body-1-bold;

  @include mq($from: l) {
    &-desktop.text-body-1-bold-desktop {
      @include text-body-1-bold;
    }
  }
}

.text-body-2-bold {
  @include text-body-2-bold;

  @include mq($from: l) {
    &-desktop.text-body-2-bold-desktop {
      @include text-body-2-bold;
    }
  }
}

.text-body-small {
  @include text-body-small;

  @include mq($from: l) {
    &-desktop.text-body-small-desktop {
      @include text-body-small;
    }
  }
}

.text-body-eyebrow {
  @include text-body-eyebrow;

  @include mq($from: l) {
    &-desktop.text-body-eyebrow-desktop {
      @include text-body-eyebrow;
    }
  }
}

.text-body-1-mobile {
  @include text-body-1-mobile;

  @include mq($from: l) {
    &-desktop.text-body-1-mobile-desktop {
      @include text-body-1-mobile;
    }
  }
}

.text-body-1-bold-mobile {
  @include text-body-1-bold-mobile;

  @include mq($from: l) {
    &-desktop.text-body-1-bold-mobile-desktop {
      @include text-body-1-bold-mobile;
    }
  }
}

.text-body-2-mobile {
  @include text-body-2-mobile;

  @include mq($from: l) {
    &-desktop.text-body-2-mobile-desktop {
      @include text-body-2-mobile;
    }
  }
}

.text-body-2-bold-mobile {
  @include text-body-2-bold-mobile;

  @include mq($from: l) {
    &-desktop.text-body-2-bold-mobile-desktop {
      @include text-body-2-bold-mobile;
    }
  }
}

.text-body-small-mobile {
  @include text-body-small-mobile;

  @include mq($from: l) {
    &-desktop.text-body-small-mobile-desktop {
      @include text-body-small-mobile;
    }
  }
}

.text-body-caption {
  @include text-body-caption;

  @include mq($from: l) {
    &-desktop.text-body-caption-desktop {
      @include text-body-caption;
    }
  }
}

.text-utility-utility {
  @include text-utility-utility;

  @include mq($from: l) {
    &-desktop.text-utility-utility-desktop {
      @include text-utility-utility;
    }
  }
}

.text-utility-utility-small {
  @include text-utility-utility-small;

  @include mq($from: l) {
    &-desktop.text-utility-utility-small-desktop {
      @include text-utility-utility-small;
    }
  }
}

.text-utility-utility-mobile {
  @include text-utility-utility-mobile;

  @include mq($from: l) {
    &-desktop.text-utility-utility-mobile-desktop {
      @include text-utility-utility-mobile;
    }
  }
}

.text-utility-utility-small-mobile {
  @include text-utility-utility-small-mobile;

  @include mq($from: l) {
    &-desktop.text-utility-utility-small-mobile-desktop {
      @include text-utility-utility-small-mobile;
    }
  }
}

.text-blog-heading-xl {
  @include text-blog-heading-xl;

  @include mq($from: l) {
    &-desktop.text-blog-heading-xl-desktop {
      @include text-blog-heading-xl;
    }
  }
}

.text-blog-heading-h1 {
  @include text-blog-heading-h1;

  @include mq($from: l) {
    &-desktop.text-blog-heading-h1-desktop {
      @include text-blog-heading-h1;
    }
  }
}

.text-blog-heading-h2 {
  @include text-blog-heading-h2;

  @include mq($from: l) {
    &-desktop.text-blog-heading-h2-desktop {
      @include text-blog-heading-h2;
    }
  }
}

.text-blog-heading-h3 {
  @include text-blog-heading-h3;

  @include mq($from: l) {
    &-desktop.text-blog-heading-h3-desktop {
      @include text-blog-heading-h3;
    }
  }
}

.text-blog-heading-h4 {
  @include text-blog-heading-h4;

  @include mq($from: l) {
    &-desktop.text-blog-heading-h4-desktop {
      @include text-blog-heading-h4;
    }
  }
}

.text-blog-body {
  @include text-blog-body;

  @include mq($from: l) {
    &-desktop.text-blog-body-desktop {
      @include text-blog-body;
    }
  }
}

.text-blog-body-caption {
  @include text-blog-body-caption;

  @include mq($from: l) {
    &-desktop.text-blog-body-caption-desktop {
      @include text-blog-body-caption;
    }
  }
}

.text-blog-body-pullquote {
  @include text-blog-body-pullquote;

  @include mq($from: l) {
    &-desktop.text-blog-body-pullquote-desktop {
      @include text-blog-body-pullquote;
    }
  }
}

.text-blog-heading-xl-mobile {
  @include text-blog-heading-xl-mobile;

  @include mq($from: l) {
    &-desktop.text-blog-heading-xl-mobile-desktop {
      @include text-blog-heading-xl-mobile;
    }
  }
}

.text-blog-heading-h1-mobile {
  @include text-blog-heading-h1-mobile;

  @include mq($from: l) {
    &-desktop.text-blog-heading-h1-mobile-desktop {
      @include text-blog-heading-h1-mobile;
    }
  }
}

.text-blog-heading-h2-mobile {
  @include text-blog-heading-h2-mobile;

  @include mq($from: l) {
    &-desktop.text-blog-heading-h2-mobile-desktop {
      @include text-blog-heading-h2-mobile;
    }
  }
}

.text-blog-heading-h3-mobile {
  @include text-blog-heading-h3-mobile;

  @include mq($from: l) {
    &-desktop.text-blog-heading-h3-mobile-desktop {
      @include text-blog-heading-h3-mobile;
    }
  }
}

.text-blog-heading-h4-mobile {
  @include text-blog-heading-h4-mobile;

  @include mq($from: l) {
    &-desktop.text-blog-heading-h4-mobile-desktop {
      @include text-blog-heading-h4-mobile;
    }
  }
}

.text-blog-body-mobile {
  @include text-blog-body-mobile;

  @include mq($from: l) {
    &-desktop.text-blog-body-mobile-desktop {
      @include text-blog-body-mobile;
    }
  }
}

.text-blog-body-caption-mobile {
  @include text-blog-body-caption-mobile;

  @include mq($from: l) {
    &-desktop.text-blog-body-caption-mobile-desktop {
      @include text-blog-body-caption-mobile;
    }
  }
}

.text-blog-body-pullquote-mobile {
  @include text-blog-body-pullquote-mobile;

  @include mq($from: l) {
    &-desktop.text-blog-body-pullquote-mobile-desktop {
      @include text-blog-body-pullquote-mobile;
    }
  }
}

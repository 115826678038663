@import '@we-make-websites/styles-lib/src/config/configuration';
/**
 * Styles: Promo Banner
 * -----------------------------------------------------------
 *
 */
.promoBanner {
  $parent: &;
  background: var(--banner-background-color);
  color: var(--banner-text-color);
  height: 32px;
  margin: auto;
  width: 100%;

  &__carousel {
    grid-column: 2;
    grid-row: 1;
  }

  &__sideNavWrapper {
    flex: 1;
    min-width: fit-content;
  }

  &__carouselWrapper,
  &__carouselList,
  &__container {
    height: 100%;
  }

  &__container {
    overflow: hidden;
  }

  &__control {
    align-items: center;
    background: none;
    border: 0;
    color: var(--banner-text-color);
    display: flex;

    :global(.icon) {
      width: 11px;
    }

    &:disabled {
      cursor: initial;
      opacity: 0.5;
      pointer-events: none;
    }

    &Next {
      grid-column: 3;
      grid-row: 1;
    }

    &Previous {
      grid-column: 1;
      grid-row: 1;
    }
  }

  &__slide {
    color: var(--banner-text-color);
    align-items: center;
    display: flex;
    justify-content: center;
  }

  &__link {
    align-items: center;
    color: var(--banner-text-color);
    display: grid;
    font-weight: var(--font-weight-bold);
    justify-content: center;
    height: 100%;
    text-align: center;
  }

  &__linkSingle {
    margin-left: 5px;
  }

  &__carouselWrapper {
    background: var(--banner-background-color);
    color: var(--banner-text-color);
    display: grid;
    grid-template-columns: auto 1fr auto;
    height: 100%;
    margin: auto;
    width: 100%;
    margin: auto;
    max-width: 310px;
  }

  &__sideNavWrapper {
    display: flex;
    column-gap: var(--spacing-l);
    height: 100%;
    flex-direction: row;
    justify-content: flex-end;

    :global(.icon) {
      width: 11px;
    }
  }

  &__sideSingleNavWrapper {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
  }

  &__navLink {
    color: var(--banner-text-color);

    :global(.icon) {
      margin-right: var(--spacing-2xs);
    }

    :global(.icon__location) {
      width: 11px;
    }

    :global(.icon__mobile) {
      width: 9px;
    }

    :global(.icon__pro) {
      width: 7.6px;
    }
  }

  @include mq($from: xl) {
    &__carouselWrapper {
      width: min-content;
      max-width: unset;
    }

    &__carousel,
    &__slide {
      width: 400px;
    }

    &__container {
      display: flex;
      justify-content: flex-end;
    }

    &__leftContainer {
      flex: 1;
      display: flex;
      align-items: center;
    }

    :global(li[aria-hidden='true']) {
      width: 0;
    }

    &__sideNavWrapper {
      flex: 1;
      min-width: fit-content;
    }
  }

  @include mq($until: nav-xl) {
    &__sideNavWrapper,
    &__leftContainer {
      display: none;
      right: var(--gutter-mobile);
    }
  }
}

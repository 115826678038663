@import '@we-make-websites/styles-lib/src/config/configuration';
.localeSwitcher {
  --nav-link-color: var(--banner-text-color);
  display: flex;
  align-items: center;
  gap: var(--spacing-2xs);

  &__navLink {
    color: var(--nav-link-color);
    padding: var(--spacing-2xs);
    text-transform: uppercase;
    font-family: var(--font-family-sans);
    font-weight: var(--font-weight-medium);
    letter-spacing: var(--letter-spacing-wider);
    text-decoration: none;
    position: relative;
    cursor: pointer;

    &:before {
      content: '';
      height: 1px;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: var(--nav-link-color);
      opacity: 0;
    }

    &:hover,
    &--active {
      &:before {
        opacity: 1;
      }
    }
  }

  &--mobile {
    // Color text primary could not be found here, so black is used
    //--nav-link-color: var(--color-text-primary);
    --nav-link-color: var(--color-black);
    gap: var(--spacing-m);
    margin-block-start: calc(-1 * var(--spacing-xs));
  }

  &--mobile &__navLink {
    padding: var(--spacing-xs);
  }
}

/**
 * Base: Classes
 * -----------------------------------------------------------------------------
 * Automatically generated by `design` command, do not edit.
 *
 */
// stylelint-disable

// Typography

@mixin text-heading-xl {
  font-family: var(--font-family-sans);
  font-size: var(--font-size-5xl);
  font-weight: var(--font-weight-regular);
  letter-spacing: var(--letter-spacing-tight);
  line-height: var(--line-height-xl);
  text-decoration: none;
}

@mixin text-heading-h1 {
  font-family: var(--font-family-sans);
  font-size: var(--font-size-4xl);
  font-weight: var(--font-weight-medium);
  letter-spacing: var(--letter-spacing-tight);
  line-height: var(--line-height-h1);
  text-decoration: none;
}

@mixin text-heading-h2 {
  font-family: var(--font-family-sans);
  font-size: var(--font-size-3xl);
  font-weight: var(--font-weight-medium);
  letter-spacing: var(--letter-spacing-tight);
  line-height: var(--line-height-h2);
  text-decoration: none;
}

@mixin text-heading-h3 {
  font-family: var(--font-family-sans);
  font-size: var(--font-size-3xl);
  font-weight: var(--font-weight-regular);
  letter-spacing: var(--letter-spacing-tight);
  line-height: var(--line-height-h2);
  text-decoration: none;
}

@mixin text-heading-h4 {
  font-family: var(--font-family-sans);
  font-size: var(--font-size-2xl);
  font-weight: var(--font-weight-regular);
  letter-spacing: var(--letter-spacing-tight);
  line-height: var(--line-height-h4);
  text-decoration: none;
}

@mixin text-heading-h4-bold {
  font-family: var(--font-family-sans);
  font-size: var(--font-size-2xl);
  font-weight: var(--font-weight-medium);
  letter-spacing: var(--letter-spacing-tight);
  line-height: var(--line-height-h4);
  text-decoration: none;
}

@mixin text-heading-h5 {
  font-family: var(--font-family-sans);
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-medium);
  letter-spacing: var(--letter-spacing-tight);
  line-height: var(--line-height-h2-m);
  text-decoration: none;
}

@mixin text-heading-h6 {
  font-family: var(--font-family-sans);
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-regular);
  letter-spacing: var(--letter-spacing-tight);
  line-height: var(--line-height-h2-m);
  text-decoration: none;
}

@mixin text-heading-h7 {
  font-family: var(--font-family-sans);
  font-size: var(--font-size-l);
  font-weight: var(--font-weight-medium);
  letter-spacing: var(--letter-spacing-tight);
  line-height: var(--line-height-body1);
  text-decoration: none;
}

@mixin text-heading-xl-mobile {
  font-family: var(--font-family-sans);
  font-size: var(--font-size-5xl-m);
  font-weight: var(--font-weight-regular);
  letter-spacing: var(--letter-spacing-tight);
  line-height: var(--line-height-xl-m);
  text-decoration: none;
}

@mixin text-heading-h1-mobile {
  font-family: var(--font-family-sans);
  font-size: var(--font-size-4xl-m);
  font-weight: var(--font-weight-medium);
  letter-spacing: var(--letter-spacing-tight);
  line-height: var(--line-height-h1-m);
  text-decoration: none;
}

@mixin text-heading-h2-mobile {
  font-family: var(--font-family-sans);
  font-size: var(--font-size-2xl);
  font-weight: var(--font-weight-medium);
  letter-spacing: var(--letter-spacing-tight);
  line-height: var(--line-height-h2-m);
  text-decoration: none;
}

@mixin text-heading-h3-mobile {
  font-family: var(--font-family-sans);
  font-size: var(--font-size-2xl);
  font-weight: var(--font-weight-regular);
  letter-spacing: var(--letter-spacing-tight);
  line-height: var(--line-height-h2-m);
  text-decoration: none;
}

@mixin text-heading-h4-mobile {
  font-family: var(--font-family-sans);
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-regular);
  letter-spacing: var(--letter-spacing-normal);
  line-height: var(--line-height-h4-m);
  text-decoration: none;
}

@mixin text-heading-h5-mobile {
  font-family: var(--font-family-sans);
  font-size: var(--font-size-l);
  font-weight: var(--font-weight-medium);
  letter-spacing: var(--letter-spacing-normal);
  line-height: var(--line-height-h5-m);
  text-decoration: none;
}

@mixin text-heading-h6-mobile {
  font-family: var(--font-family-sans);
  font-size: var(--font-size-l);
  font-weight: var(--font-weight-regular);
  letter-spacing: var(--letter-spacing-normal);
  line-height: var(--line-height-h5-m);
  text-decoration: none;
}

@mixin text-heading-h7-mobile {
  font-family: var(--font-family-sans);
  font-size: var(--font-size-m);
  font-weight: var(--font-weight-medium);
  letter-spacing: var(--letter-spacing-tight);
  line-height: var(--line-height-h5-m);
  text-decoration: none;
}

@mixin text-body-1 {
  font-family: var(--font-family-sans);
  font-size: var(--font-size-m);
  font-weight: var(--font-weight-regular);
  letter-spacing: var(--letter-spacing-normal);
  line-height: var(--line-height-body1);
  text-decoration: none;
}

@mixin defaults-body {
  @include text-body-1;
}

@mixin text-body-2 {
  font-family: var(--font-family-sans);
  font-size: var(--font-size-s);
  font-weight: var(--font-weight-regular);
  letter-spacing: var(--letter-spacing-normal);
  line-height: var(--line-height-body2);
  text-decoration: none;
}

@mixin text-body-1-bold {
  font-family: var(--font-family-sans);
  font-size: var(--font-size-m);
  font-weight: var(--font-weight-medium);
  letter-spacing: var(--letter-spacing-normal);
  line-height: var(--line-height-body1);
  text-decoration: none;
}

@mixin text-body-2-bold {
  font-family: var(--font-family-sans);
  font-size: var(--font-size-s);
  font-weight: var(--font-weight-medium);
  letter-spacing: var(--letter-spacing-normal);
  line-height: var(--line-height-body2);
  text-decoration: none;
}

@mixin text-body-small {
  font-family: var(--font-family-sans);
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-regular);
  letter-spacing: var(--letter-spacing-normal);
  line-height: var(--line-height-body-small);
  text-decoration: none;
}

@mixin text-body-eyebrow {
  font-family: var(--font-family-sans);
  font-size: var(--font-size-l);
  font-weight: var(--font-weight-medium);
  letter-spacing: var(--letter-spacing-wider);
  line-height: var(--line-height-h2-m);
  text-decoration: none;
  text-transform: uppercase;
}

@mixin text-body-1-mobile {
  font-family: var(--font-family-sans);
  font-size: var(--font-size-s);
  font-weight: var(--font-weight-regular);
  letter-spacing: var(--letter-spacing-normal);
  line-height: var(--line-height-body1-m);
  text-decoration: none;
}

@mixin text-body-1-bold-mobile {
  font-family: var(--font-family-sans);
  font-size: var(--font-size-s);
  font-weight: var(--font-weight-medium);
  letter-spacing: var(--letter-spacing-normal);
  line-height: var(--line-height-body1-m);
  text-decoration: none;
}

@mixin text-body-2-mobile {
  font-family: var(--font-family-sans);
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-regular);
  letter-spacing: var(--letter-spacing-normal);
  line-height: var(--line-height-body2);
  text-decoration: none;
}

@mixin text-body-2-bold-mobile {
  font-family: var(--font-family-sans);
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-medium);
  letter-spacing: var(--letter-spacing-normal);
  line-height: var(--line-height-body2);
  text-decoration: none;
}

@mixin text-body-small-mobile {
  font-family: var(--font-family-sans);
  font-size: var(--font-size-2xs);
  font-weight: var(--font-weight-regular);
  letter-spacing: var(--letter-spacing-normal);
  line-height: var(--line-height-body-small-m);
  text-decoration: none;
}

@mixin text-body-caption {
  font-family: var(--font-family-sans);
  font-size: var(--font-size-caption-m);
  font-weight: var(--font-weight-regular);
  letter-spacing: var(--letter-spacing-normal);
  line-height: var(--line-height-body-small-m);
  text-decoration: none;
}

@mixin text-utility-utility {
  font-family: var(--font-family-sans);
  font-size: var(--font-size-s);
  font-weight: var(--font-weight-medium);
  letter-spacing: var(--letter-spacing-wider);
  line-height: var(--line-height-utility);
  text-decoration: none;
  text-transform: uppercase;
}

@mixin defaults-button {
  @include text-utility-utility;
}

@mixin text-utility-utility-small {
  font-family: var(--font-family-sans);
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-medium);
  letter-spacing: var(--letter-spacing-wider);
  line-height: var(--line-height-body-small-m);
  text-decoration: none;
  text-transform: uppercase;
}

@mixin text-utility-utility-mobile {
  font-family: var(--font-family-sans);
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-medium);
  letter-spacing: var(--letter-spacing-wider);
  line-height: var(--line-height-utility-m);
  text-decoration: none;
  text-transform: uppercase;
}

@mixin text-utility-utility-small-mobile {
  font-family: var(--font-family-sans);
  font-size: var(--font-size-2xs);
  font-weight: var(--font-weight-medium);
  letter-spacing: var(--letter-spacing-wider);
  line-height: var(--line-height-utility-small-m);
  text-decoration: none;
  text-transform: uppercase;
}

@mixin text-blog-heading-xl {
  font-family: var(--font-family-serif);
  font-size: var(--font-size-blog-xl);
  font-weight: var(--font-weight-regular);
  letter-spacing: var(--letter-spacing-tight);
  line-height: var(--line-height-xl-blog);
  text-decoration: none;
}

@mixin text-blog-heading-h1 {
  font-family: var(--font-family-serif);
  font-size: var(--font-size-4xl);
  font-weight: var(--font-weight-regular);
  letter-spacing: var(--letter-spacing-tight);
  line-height: var(--line-height-h1);
  text-decoration: none;
}

@mixin text-blog-heading-h2 {
  font-family: var(--font-family-serif);
  font-size: var(--font-size-3xl);
  font-weight: var(--font-weight-regular);
  letter-spacing: var(--letter-spacing-tight);
  line-height: var(--line-height-h2);
  text-decoration: none;
}

@mixin text-blog-heading-h3 {
  font-family: var(--font-family-sans);
  font-size: var(--font-size-blog-h1-m);
  font-weight: var(--font-weight-regular);
  letter-spacing: var(--letter-spacing-tight);
  line-height: var(--line-height-h3);
  text-decoration: none;
}

@mixin text-blog-heading-h4 {
  font-family: var(--font-family-serif);
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-regular);
  letter-spacing: var(--letter-spacing-tight);
  line-height: var(--line-height-h4-blog);
  text-decoration: none;
}

@mixin text-blog-body {
  font-family: var(--font-family-sans);
  font-size: var(--font-size-m);
  font-weight: var(--font-weight-regular);
  letter-spacing: var(--letter-spacing-tight);
  line-height: var(--line-height-body-blog);
  min-height: var(--line-height-body-blog);
  text-decoration: none;
}

@mixin text-blog-body-caption {
  font-family: var(--font-family-sans);
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-regular);
  letter-spacing: var(--letter-spacing-normal);
  line-height: var(--line-height-body2);
  text-decoration: none;
}

@mixin text-blog-body-pullquote {
  font-family: var(--font-family-serif);
  font-size: var(--font-size-blog-xl-m);
  font-weight: var(--font-weight-regular);
  letter-spacing: var(--letter-spacing-tight);
  line-height: var(--line-height-pullquote-blog);
  text-decoration: none;
}

@mixin text-blog-heading-xl-mobile {
  font-family: var(--font-family-serif);
  font-size: var(--font-size-blog-xl-m);
  font-weight: var(--font-weight-regular);
  letter-spacing: var(--letter-spacing-tight);
  line-height: var(--line-height-xl-blog-m);
  text-decoration: none;
}

@mixin text-blog-heading-h1-mobile {
  font-family: var(--font-family-serif);
  font-size: var(--font-size-blog-h1-m);
  font-weight: var(--font-weight-regular);
  letter-spacing: var(--letter-spacing-tight);
  line-height: var(--line-height-h1-blog-m);
  text-decoration: none;
}

@mixin text-blog-heading-h2-mobile {
  font-family: var(--font-family-serif);
  font-size: var(--font-size-2xl);
  font-weight: var(--font-weight-regular);
  letter-spacing: var(--letter-spacing-tight);
  line-height: var(--line-height-h2-blog-m);
  text-decoration: none;
}

@mixin text-blog-heading-h3-mobile {
  font-family: var(--font-family-sans);
  font-size: var(--font-size-l);
  font-weight: var(--font-weight-regular);
  letter-spacing: var(--letter-spacing-tight);
  line-height: var(--line-height-h3-blog-m);
  text-decoration: none;
}

@mixin text-blog-heading-h4-mobile {
  font-family: var(--font-family-serif);
  font-size: var(--font-size-m);
  font-weight: var(--font-weight-regular);
  letter-spacing: var(--letter-spacing-tight);
  line-height: var(--line-height-h4-blog-m);
  text-decoration: none;
}

@mixin text-blog-body-mobile {
  font-family: var(--font-family-sans);
  font-size: var(--font-size-s);
  font-weight: var(--font-weight-regular);
  letter-spacing: var(--letter-spacing-normal);
  line-height: var(--line-height-body1-m);
  min-height: var(--line-height-body1-m);
  text-decoration: none;
}

@mixin text-blog-body-caption-mobile {
  font-family: var(--font-family-sans);
  font-size: var(--font-size-caption-m);
  font-weight: var(--font-weight-regular);
  letter-spacing: var(--letter-spacing-normal);
  line-height: var(--line-height-body-small);
  text-decoration: none;
}

@mixin text-blog-body-pullquote-mobile {
  font-family: var(--font-family-serif);
  font-size: var(--font-size-blog-pullquote-m);
  font-weight: var(--font-weight-regular);
  letter-spacing: var(--letter-spacing-tight);
  line-height: var(--line-height-pullquote-blog-m);
  text-decoration: none;
}

/**
 * Helper: Layout
 * -----------------------------------------------------------------------------
 * Layout classes for site.
 *
 */

/**
 * Primary grid layout.
 */
.site-grid {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto 1fr auto;
  min-height: 100vh;

  &#{&}--header-only {
    min-height: unset;
  }
}

.site-header-section {
  grid-row-start: 1;
  position: relative;
}

.site-main-section {
  grid-row-start: 2;
  padding-block-start: var(--header-height);
}

.site-footer-section {
  grid-row-start: 3;
}

/**
 * Container.
 */
.container {
  @include container;
}

/**
 * Sections.
 */
.component-section {
  margin-block-end: var(--margin-mobile);
  margin-block-start: var(--margin-mobile);

  @include mq($from: m) {
    margin-block-end: var(--margin-tablet);
    margin-block-start: var(--margin-tablet);
  }

  @include mq($from: l) {
    margin-block-end: var(--margin-desktop);
    margin-block-start: var(--margin-desktop);
  }
}

/* Content Section used to apply spacing between modules. */
.content-section {
  margin-block-end: var(--spacing-xs);
}

/**
 * Spacing utility classes.
 */
.padding {
  padding-block-end: var(--margin-mobile);
  padding-block-start: var(--margin-mobile);

  @include mq($from: m) {
    padding-block-end: var(--margin-tablet);
    padding-block-start: var(--margin-tablet);
  }

  @include mq($from: l) {
    padding-block-end: var(--margin-desktop);
    padding-block-start: var(--margin-desktop);
  }
}

.padding-top,
.padding-block-start {
  padding-block-start: var(--margin-mobile);

  @include mq($from: m) {
    padding-block-start: var(--margin-tablet);
  }

  @include mq($from: l) {
    padding-block-start: var(--margin-desktop);
  }
}

.padding-bottom,
.padding-block-end {
  padding-block-end: var(--margin-mobile);

  @include mq($from: m) {
    padding-block-end: var(--margin-tablet);
  }

  @include mq($from: l) {
    padding-block-end: var(--margin-desktop);
  }
}

/**
 * No spacing utility classes.
 */
.no-padding {
  padding: 0;
}

.no-padding-top,
.no-padding-block-start {
  padding-block-start: 0;
}

.no-padding-bottom,
.no-padding-block-end {
  padding-block-end: 0;
}

.no-margin {
  margin: 0;
}

.no-margin-top,
.no-margin-block-start {
  margin-block-start: 0;
}

.no-margin-bottom,
.no-margin-block-end {
  margin-block-end: 0;
}

.skip-main {
  left: -999px;
  position: absolute;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
  z-index: -999;
}

.skip-main:focus,
.skip-main:active {
  color: #fff;
  background-color: #000;
  left: auto;
  top: auto;
  width: 30%;
  height: auto;
  overflow: auto;
  margin: 10px 35%;
  padding: 5px;
  border-radius: 15px;
  border: 4px solid yellow;
  text-align: center;
  font-size: 1.2em;
  z-index: 999;
}

#onetrust-policy-text {
  word-break: break-word;
}

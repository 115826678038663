@import '@we-make-websites/styles-lib/src/config/configuration';
/**
 * Styles: DesktopMenuMain Styles
 * -----------------------------------------------------------------------------
 * Base styles for DesktopMenuMain and its states.
 *
 */
.desktopMenuMain {
  height: 100%;
  margin: 0 30px;

  &__navigationList {
    height: 100%;
  }

  &__navigationHeader {
    font-weight: 700 !important;
  }

  &__navigationItem {
    display: flex;
    height: 100%;
    border: 0;
    border-bottom: 2px solid transparent;
    text-align: center;

    .meganav {
      transition: opacity var(--timing-normal) var(--easing-normal),
        visibility 0s linear var(--timing-normal),
        top 0s linear var(--timing-normal);
      text-align: left;
    }

    &.hover {
      border-bottom: 2px solid var(--color-border-dark);

      .meganav {
        visibility: visible;
        top: 100%;
        opacity: 1;
        z-index: 1001;
        transition: opacity var(--timing-slow) var(--easing-normal),
          visibility 0s linear 0s, top 0s linear 0s;
      }
    }

    :global {
      .focus-visible {
        opacity: 1;
      }
    }
  }

  &__navigationLinkContainer {
    position: relative;
  }

  &__navigationLink {
    align-items: center;
    display: flex;
    height: 100%;
    text-transform: uppercase;
    line-height: 1.2 !important;
  }

  &__navigationToggle {
    @include button-reset;
    color: inherit;
    height: 24px;
    opacity: 0;
    padding: 0;
    position: absolute;
    bottom: 0;
    right: 50%;
    transform: translateX(50%);

    &:focus-visible {
      opacity: 1;
    }
  }

  /**
   * Media queries.
   */
  @include mq($until: nav-xl) {
    display: none;
  }

  @include mq($from: l) {
    &__navigationList {
      align-items: center;
      justify-content: center;
      display: flex;
    }
    &__navigationItem {
      &:not(:last-child) {
        margin-inline-end: var(--spacing-xl);
      }
    }
  }

  @include mq($from: nav-xl) {
    // &__navigationItem {
    //   &:not(:last-child) {
    //     margin-inline-end: var(--spacing-xl);
    //   }
    // }

    &__navigationToggle {
      height: 100%;
      right: calc(var(--spacing-l) * -1);
      top: 0;
      transform: none;
    }
  }
}

/**
 * Import meganav styles.
 */
@import './styles/meganav';

@import '@we-make-websites/styles-lib/src/config/configuration';
.previewCookieBanner {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: #ff808b;
  border-top: 1px solid #e0e0e0;
  font-weight: bold;
  text-align: center;
  padding: 10px;
  color: white;
  height: 80px;

  button {
    background-color: white;
    color: #ff808b;
    border: 1px solid #ff808b;
    padding: 5px 10px;
    margin-top: 2px;
    cursor: pointer;
    transition: all 0.3s;
    &:hover {
      background-color: #ff808b;
      color: white;
      border-color: white;
    }
  }
}

/**
 * Styles: Meganav (megnav)
 * -----------------------------------------------------------------------------
 * Base styles for meganav and its states.
 *
 */
.meganav {
  $parent: &;
  background: var(--color-background-light);
  position: absolute;
  display: block;
  left: -1px;
  margin: 0 calc(var(--margin-desktop) * -1);
  cursor: default;
  visibility: hidden;
  top: -9999em;
  overflow: hidden;
  opacity: 0;
  padding: 0 var(--margin-desktop);
  transition: opacity var(--timing-slow) var(--easing-normal),
    visibility 0s linear var(--timing-slow), top 0s linear var(--timing-slow);
  width: calc(100% + var(--margin-desktop) * 2 + 2px);
  border-top: 1px solid var(--color-border-dividers);

  :global(.menu-item__products) {
    justify-content: space-between;

    #{$parent}__Lists {
      gap: 70px;
    }
  }

  &__Menus {
    display: flex;
    flex-flow: row wrap;
    gap: 45px;
    justify-content: center;
    padding: var(--spacing-m) 0 var(--spacing-l);
  }

  &__Lists {
    display: flex;
    flex-direction: row;
    gap: 45px;
  }

  &__MenuTitle {
    border: 0;
    border-bottom: 1px solid var(--color-border-light);
    color: var(--color-secondary);
    margin: 0 0 var(--spacing-2xs);
    padding: var(--spacing-m) 0;
    text-transform: uppercase;
  }

  & &__MenuLink {
    align-items: flex-end;
    display: flex;
    gap: 10px;
    line-height: var(--line-height-utility-small-m);
    text-transform: capitalize;

    img {
      border-radius: 50px;
    }

    &:hover {
      text-decoration: underline;
    }
  }

  &__MenuList {
    display: grid;
    gap: var(--spacing-m);
  }

  &__BlockBody {
    display: flex;
    flex-flow: row wrap;
    gap: 45px;
  }

  &__BlockBodyMargin {
    // Make up space if there is no block title.
    margin-block-start: 18px;
  }

  &__BlockHeading {
    margin-block-end: var(--spacing-2xs);
    padding: var(--spacing-m) 0;
    font-weight: 700 !important;
  }

  &__BlockColumn {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-m);
    text-decoration: none;
    max-width: 222px;
  }

  &__menuImage {
    object-fit: cover;
  }
}

@font-face {
  font-family: 'Sharp Grotesk';
  font-style: normal;
  font-weight: 300;
  src: url('/fonts/SharpGroteskLight-20.woff2') format('woff2'),
    url('/fonts/SharpGroteskLight-20.woff') format('woff');
}

@font-face {
  font-family: 'Sharp Grotesk';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/SharpGroteskBook-20.woff2') format('woff2'),
    url('/fonts/SharpGroteskBook-20.woff') format('woff');
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('/fonts/ProximaNova-Thin.woff2') format('woff2'),
    url('/fonts/ProximaNova-Thin.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('/fonts/ProximaNova-Bold.woff2') format('woff2'),
    url('/fonts/ProximaNova-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('/fonts/ProximaNova-Regular.woff2') format('woff2'),
    url('/fonts/ProximaNova-Regular.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ABC Arizona Mix';
  font-style: normal;
  font-weight: 300;
  src: url('/fonts/ABCArizonaMix-Light.woff2') format('woff2'),
    url('/fonts/ABCArizonaMix-Light.woff') format('woff');
}

@font-face {
  font-family: 'ABC Arizona Mix';
  font-style: italic;
  font-weight: 300;
  src: url('/fonts/ABCArizonaMix-LightItalic.woff') format('woff2'),
    url('/fonts/ABCArizonaMix-LightItalic.woff') format('woff');
}

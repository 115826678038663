/**
 * Config: CSS variables
 * -----------------------------------------------------------------------------
 * Automatically generated by `design` command, do not edit.
 *
 */
// stylelint-disable

:root,
::before,
::after {
  // Breakpoint
  --breakpoint-xs: 0; // 0px
  --breakpoint-s: 36rem; // 576px
  --breakpoint-m: 48rem; // 768px
  --breakpoint-l: 64rem; // 1024px
  --breakpoint-xl: 85.375rem; // 1366px

  // Color
  --color-background-dark: rgb(0, 0, 0); // #000000
  --color-background-grey: rgb(246, 246, 246); // #f6f6f6
  --color-background-light: rgb(255, 255, 255); // #ffffff
  --color-black: rgb(0, 0, 0); // #000000
  --color-blog: rgb(155, 23, 138); // #9b178a
  --color-blog-fuschia: rgb(155, 23, 138); // #9b178a
  --color-border-dark: rgb(0, 0, 0); // #000000
  --color-border-dividers: rgb(202, 202, 202); // #cacaca
  --color-border-input-field: rgb(96, 96, 96); // #606060
  --color-extra-light-grey: rgb(246, 246, 246); // #f6f6f6
  --color-input-field-grey: rgb(96, 96, 96); // #606060
  --color-light-grey: rgb(202, 202, 202); // #cacaca
  --color-light-mid-grey: rgb(153, 153, 153); // #999999
  --color-mid-grey: rgb(119, 118, 118); // #777676
  --color-sale: rgb(220, 66, 74); // #dc424a
  --color-sale-and-error: rgb(220, 66, 74); // #dc424a
  --color-support-error: rgb(220, 66, 74); // #dc424a
  --color-support-focus: rgb(96, 96, 96); // #606060
  --color-support-success: rgb(0, 0, 0); // #000000
  --color-support-warning: rgb(220, 66, 74); // #dc424a
  --color-text-dark: rgb(0, 0, 0); // #000000
  --color-text-light: rgb(255, 255, 255); // #ffffff
  --color-text-light-mid-grey: rgb(153, 153, 153); // #999999
  --color-text-mid-grey: rgb(119, 118, 118); // #777676
  --color-text-sale: rgb(220, 66, 74); // #dc424a
  --color-white: rgb(255, 255, 255); // #ffffff

  // Columns
  --columns-mobile: 4;
  --columns-tablet: 6;
  --columns-desktop: 12;

  // Easing
  --easing-normal: ease-out;

  // Focus-ring
  --focus-ring-width: 5px;

  // Font-family
  --font-family-sans: 'Sharp Grotesk';
  --font-family-serif: 'ABC Arizona Mix';

  // Font-size
  --font-size-caption-m: 0.6875rem; // 11px
  --font-size-3xl-m: 1.375rem; // 22px
  --font-size-4xl-m: 1.875rem; // 30px
  --font-size-5xl-m: 2.5rem; // 40px
  --font-size-blog-xl: 3.75rem; // 60px
  --font-size-blog-xl-m: 2.375rem; // 38px
  --font-size-blog-h1-m: 1.75rem; // 28px
  --font-size-blog-pullquote-m: 1.6875rem; // 27px
  --font-size-2xs: 0.625rem; // 10px
  --font-size-xs: 0.75rem; // 12px
  --font-size-s: 0.875rem; // 14px
  --font-size-m: 1rem; // 16px
  --font-size-l: 1.125rem; // 18px
  --font-size-xl: 1.25rem; // 20px
  --font-size-2xl: 1.375rem; // 22px
  --font-size-3xl: 2rem; // 32px
  --font-size-4xl: 3rem; // 48px
  --font-size-5xl: 4.6875rem; // 75px

  // Font-weight
  --font-weight-regular: 300;
  --font-weight-medium: 400;

  // Form
  --form-checkbox: 16px;
  --form-radio-outer: 16px;
  --form-radio-inner: 10px;

  // Gutter
  --gutter-mobile: 1.25rem; // 20px
  --gutter-tablet: 1.25rem; // 20px
  --gutter-desktop: 1.25rem; // 20px

  // Header
  --header-height: 60px;
  --header-static-height: 60px;
  --header-visible-height: 60px;

  // Icon
  --icon-2xs: 1rem; // 16px
  --icon-xs: 1.125rem; // 18px
  --icon-s: 1.25rem; // 20px
  --icon-m: 1.5rem; // 24px
  --icon-l: 2.25rem; // 36px

  // Layer
  --layer-base: 0;
  --layer-flat: 1;
  --layer-raised: 2;
  --layer-heightened: 4;
  --layer-sticky: 8;
  --layer-window-overlay: 12;
  --layer-overlay: 16;
  --layer-temporary: 20;

  // Letter-spacing
  --letter-spacing-wide: 0.02em;
  --letter-spacing-wider: 0.07em;
  --letter-spacing-tight: -0.02em;
  --letter-spacing-normal: 0%;

  // Line-height
  --line-height-xl: 75px;
  --line-height-xl-m: 44px;
  --line-height-h1: 56px;
  --line-height-h1-m: 36px;
  --line-height-h2: 38px;
  --line-height-h2-m: 26px;
  --line-height-h3: 40px;
  --line-height-h4: 30px;
  --line-height-h4-m: 27px;
  --line-height-h5: 26px;
  --line-height-h5-m: 24px;
  --line-height-h6: 26px;
  --line-height-body1: 22px;
  --line-height-body1-m: 19px;
  --line-height-body2: 18px;
  --line-height-body-small: 16px;
  --line-height-body-small-m: 14px;
  --line-height-utility: 14px;
  --line-height-utility-m: 18px;
  --line-height-utility-small: 12px;
  --line-height-utility-small-m: 16px;
  --line-height-xl-blog: 66px;
  --line-height-xl-blog-m: 42px;
  --line-height-h1-blog-m: 32px;
  --line-height-h2-blog-m: 27px;
  --line-height-h3-blog-m: 26px;
  --line-height-h4-blog: 25px;
  --line-height-h4-blog-m: 20px;
  --line-height-body-blog: 24px;
  --line-height-pullquote-blog: 46px;
  --line-height-pullquote-blog-m: 33px;
  --line-height-baseline: 100%;

  // Margin
  --margin-mobile: 1.25rem; // 20px
  --margin-tablet: 1.25rem; // 20px
  --margin-desktop: 3.125rem; // 50px

  // Max-content-width
  --max-content-width: 90rem; // 1440px

  // Scale
  --scale-base: 16px;

  // Spacing
  --spacing-4xs: 0.0625rem; // 1px
  --spacing-3xs: 0.125rem; // 2px
  --spacing-2xs: 0.25rem; // 4px
  --spacing-xs: 0.5rem; // 8px
  --spacing-s: 0.75rem; // 12px
  --spacing-m: 1rem; // 16px
  --spacing-l: 1.5rem; // 24px
  --spacing-xl: 2rem; // 32px
  --spacing-2xl: 2.5rem; // 40px
  --spacing-3xl: 3rem; // 48px
  --spacing-4xl: 3.5rem; // 56px
  --spacing-5xl: 4rem; // 64px
  --spacing-6xl: 4.5rem; // 72px
  --spacing-7xl: 5rem; // 80px
  --spacing-8xl: 5.5rem; // 88px
  --spacing-9xl: 6rem; // 96px
  --spacing-10xl: 6.5rem; // 104px
  --spacing-11xl: 7rem; // 112px
  --spacing-12xl: 7.5rem; // 120px
  --spacing-13xl: 8rem; // 128px

  // Timing
  --timing-quick: 0.1s;
  --timing-normal: 0.3s;
  --timing-slow: 0.6s;
  --timing-message: 3s;
}

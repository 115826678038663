/**
 * Styles: Hamburger
 * -----------------------------------------------------------------------------
 * Hamburger menu icon styles and animation.
 *
 */
.hamburger {
  $offset: 9px;
  $parent: &;
  $thickness: 2px;
  display: block;
  height: var(--icon-m);
  position: relative;
  width: var(--icon-m);

  &__inner,
  &__inner::before,
  &__inner::after {
    background-color: currentcolor;
    height: $thickness;
    position: absolute;
    width: var(--icon-m);
  }

  &__inner::after {
    display: none;
  }

  &__inner {
    @include transition(transform var(--timing-quick));
    left: 0;
    top: calc(50% - #{$thickness * 2});
  }

  &__inner::before,
  &__inner::after {
    content: '';
    display: block;
  }

  &__inner::before {
    @include transition(opacity var(--timing-quick), top var(--timing-quick));
    top: $offset;
  }

  &__inner::after {
    @include transition(
      bottom var(--timing-quick),
      transform var(--timing-quick)
    );
    bottom: -$offset;
  }

  /**
   * States.
   */
  &.isActive {
    top: calc($thickness - 2px);

    #{$parent}__inner,
    #{$parent}__inner::before,
    #{$parent}__inner::after {
      transition-delay: var(--timing-normal);
    }

    #{$parent}__inner {
      transform: rotate(45deg);
    }

    #{$parent}__inner::before {
      opacity: 0;
      top: 0;
    }

    #{$parent}__inner::after {
      bottom: 0;
      transform: rotate(-90deg);
    }
  }
}

@import '@we-make-websites/styles-lib/src/config/configuration';
/**
 * Component / Global: Site header
 * -----------------------------------------------------------------------------
 * Base styles for site header and its states.
 *
 */
.siteHeader {
  $parent: &;
  @include transition(transform);
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: calc(var(--layer-sticky) + 1);
  display: flex;
  flex-direction: column;

  &__promo {
    background: var(--banner-background-color);
    color: white;
    width: 100%;
  }

  &__masthead {
    align-items: center;
    background-color: var(--color-background-light);
    display: flex;
    height: 64px;
  }

  &__container {
    height: 100%;
  }

  &__grid {
    align-items: center;
    display: grid;
    grid-template-columns: 64px 1fr 64px;
    height: 100%;
    position: relative;
  }

  &__logoText {
    font-size: var(--font-size-m);
    font-weight: var(--font-weight-bold);
    line-height: var(--line-height-baseline);
  }

  &__wishlist,
  &__account {
    display: none;
  }

  &__cart {
    position: relative;
  }

  &__cartBadge {
    background-color: var(--color-primary);
    border-radius: 100%;
    color: var(--color-text-inverse);
    display: grid;
    font-size: var(--font-size-xs);
    height: 14px;
    line-height: var(--line-height-baseline);
    place-items: center;
    pointer-events: none;
    position: absolute;
    right: var(--spacing-2xs);
    top: var(--spacing-2xs);
    width: 14px;
  }

  &__logo {
    display: flex;
    justify-content: center;

    svg {
      height: 26px;
      width: 83px;
    }
  }

  &__controls {
    display: grid;
    gap: var(--spacing-m);
    grid-auto-flow: column;
  }

  &__searchMenuLoader {
    @include loading;
    height: 49px;
    margin: 0 auto;
    max-width: 1104px;
    width: 100%;
  }

  &__cartCount {
    background-color: var(--color-background-dark);
    border-radius: 50%;
    height: 17px;
    width: 17px;
    position: absolute;
    bottom: var(--spacing-3xs);
    right: var(--spacing-2xs);
  }

  &__cartCountText {
    @include text-body-small-mobile;
    color: var(--color-text-light);
    font-weight: var(--font-weight-medium);
    line-height: 18px;
  }

  /**
   * States.
   */
  &.promoCollapsed {
    // Variable --promotional-bar-height to be set
    // when creating Banner Component
    transform: translateY(calc(-1 * var(--promotional-bar-height, 32px)));
  }

  /**
   * Media queries.
   */
  @include mq($until: nav-xl) {
    &__grid {
      justify-content: space-between;
    }

    &__navigation {
      display: none;
    }
  }

  @include mq($from: nav-xl) {
    &__wishlist,
    &__account {
      display: block;
    }

    &__masthead {
      height: 88px;
    }

    &__logo {
      svg {
        height: 40px;
        width: 130px;
      }
    }

    &__grid {
      grid-template-columns: 130px 1fr auto;
    }

    &__menu {
      display: none;
    }

    &__navigationList {
      align-items: center;
      justify-content: center;
      display: flex;
    }
  }
}

/**
 * Import overriding type styles.
 */
@import './styles/hamburger';

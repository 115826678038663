/**
 * Helper: Display
 * -----------------------------------------------------------------------------
 * Display classes that affect page render.
 *
 */

/**
 * Critical CSS resets.
 * - These styles are used to override styles set by the critical CSS
 *   in the `critical.liquid` snippet.
 */
.critical-hide {
  display: unset;
}

.critical-clear {
  opacity: unset;
}

.main-content .shopify-section {
  display: unset;
}

body:not(.template-index) .main-content {
  opacity: unset;
}

/**
 * Only show when JS is not supported.
 */
.no-js:not(html) {
  display: none;

  .no-js & {
    display: unset;
  }
}

/**
 * Only show when JS is supported.
 */
.js {
  .no-js & {
    display: none;
  }
}

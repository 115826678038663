@import '@we-make-websites/styles-lib/src/config/configuration';
.socialLinks {
  $parent: &;
  display: flex;
  list-style: none;
  justify-content: space-between;
  align-items: center;

  &__short {
    justify-content: flex-start;

    #{$parent}__listItem:not(:first-of-type) {
      padding-left: 10%;
    }
  }

  &__listItem {
    &:has(#{$parent}__nonIcon) {
      display: none;
    }

    a,
    svg {
      display: block;
    }
  }

  &__facebook,
  &__instagram,
  &__pinterest,
  &__tiktok,
  &__twitter,
  &__youtube {
    height: 24px;
  }

  &__facebook {
    width: 27px;
  }

  &__instagram {
    width: 24px;
  }

  &__pinterest {
    width: 25px;
  }

  &__tiktok {
    width: 26px;
  }

  &__twitter {
    width: 28px;
  }

  &__youtube {
    width: 33px;
  }
}

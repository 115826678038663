/**
 * Config: Mixins
 * -----------------------------------------------------------------------------
 * Global and reusable utility functions to reduce common styling.
 * - Add in alphabetical order.
 *
 */
// stylelint-disable declaration-no-important, order/order

/**
 * Resets form buttons to appear as a standard text link.
 */
@mixin button-reset($background: transparent, $font: true) {
  -webkit-appearance: none;
  appearance: none;
  background-color: $background;
  border: 0;
  box-shadow: none;
  color: inherit;
  cursor: pointer;
  outline: none;
  padding: 0;
  width: auto;

  @if $font {
    font: inherit;
  }
}

/**
 *
 */
@mixin full-bleed() {
  width: 100vw;
  position: relative;
  left: 50%;
  margin-inline-end: -50vw;
  margin-inline-start: -50vw;

  @include mq($from: s) {
    width: calc(100vw - var(--scrollbar-width, 0));
    left: calc((100% + var(--scrollbar-width, 0)) / 2);
  }
}

/**
 * Forces an element to self-clear its children.
 */
@mixin clearfix() {
  &::after {
    clear: both;
    content: '';
    display: table;
  }
  *zoom: 1;
}

/**
 * Sets container properties.
 */
@mixin container() {
  margin-inline-end: auto;
  margin-inline-start: auto;
  max-width: var(--max-content-width);
  padding-inline-end: var(--margin-mobile);
  padding-inline-start: var(--margin-mobile);
  width: 100%;

  @include mq($from: m) {
    padding-inline-end: var(--margin-tablet);
    padding-inline-start: var(--margin-tablet);
  }

  @include mq($from: l) {
    padding-inline-end: var(--margin-desktop);
    padding-inline-start: var(--margin-desktop);
  }
}

/**
 * Reset container properties.
 */
@mixin container-reset() {
  margin-inline-end: 0;
  margin-inline-start: 0;
  max-width: 100%;
  padding-inline-end: 0;
  padding-inline-start: 0;
}

/**
 * Default focus styling for browsers.
 */
@mixin focus-ring($inset: false) {
  @if $inset {
    box-shadow: inset 0 0 0 1px var(--color-background-light),
      inset
        0
        0
        0
        var(--focus-ring-width, 5px)
        var(--color-support-focus, rgb(39, 110, 241));
  } @else {
    box-shadow: 0 0 0 1px var(--color-background-light),
      0
        0
        0
        var(--focus-ring-width, 5px)
        var(--color-support-focus, rgb(39, 110, 241));
  }
}

/**
 * Calculate a vh unit based on a target pixel size and browser height.
 * @param {String} $target - Target size in pixels.
 * @param {String} $context - Height of browser in pixels for given target size.
 * @returns {String}
 */
@function get-vh($target, $context) {
  @return calc((strip-unit($target) / strip-unit($context)) * 100 * 1vh);
}

/**
 * Calculate a vw unit based on a target pixel size and browser width.
 * @param {String} $target - Target size in pixels.
 * @param {String} $context - Width of browser in pixels for given target size.
 * @returns {String}
 */
@function get-vw($target, $context) {
  @return calc((strip-unit($target) / strip-unit($context)) * 100 * 1vw);
}

/**
 * Resets input elements.
 */
@mixin input-reset() {
  appearance: none;
  background-color: transparent;
  border: 0;
  box-shadow: none;
}

/**
 * Resets list elements to appear as a stacked text links.
 */
@mixin list-reset() {
  list-style-type: none;
  margin: 0;
  padding: 0;

  li {
    margin: 0;
    padding: 0;
  }
}

/**
 * Loading animation.
 */
@mixin loading(
  $display: block,
  $background: var(--color-loading, rgb(0, 0, 0, 0.15)),
  $timing: var(--timing-slow)
) {
  animation: pulse $timing infinite;
  background-color: $background;
  border-radius: 3px;
  color: transparent;
  display: $display;
  user-select: none;

  * {
    color: transparent;
    pointer-events: none;
  }
}

/**
 * Remove the unit of a length.
 * @param {Number} $number - Number to remove unit from.
 * @returns {Number}
 */
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }

  @return $number;
}

/**
 * Outputs list of transition properties.
 *
 * Accepts:
 * - $transitions: {String} List of transition properties to set
 *
 * Usage:
 * .selector {
 *   @include transition(width, height 0.5s ease-in);
 * }
 */
@mixin transition($transitions...) {
  $output: ();

  @each $transition in $transitions {
    $output: append($output, get-transition-properties($transition), comma);
  }
  transition: $output;
}

/**
 * Returns the transition properties in the correct format.
 * This function is used by @mixin transition($transitions...).
 */
@function get-transition-properties($transition) {
  $property: all;
  $duration: var(--timing-normal);
  $easing: var(--easing-normal);
  $delay: null;

  $defaultProperties: ($property, $duration, $easing, $delay);

  $properties: ();

  @for $i from 1 through length($defaultProperties) {
    $p: null;

    @if $i <= length($transition) {
      $p: nth($transition, $i);
    } @else {
      $p: nth($defaultProperties, $i);
    }

    $properties: append($properties, $p);
  }

  @return $properties;
}

/**
 * Hide element but make it accessible to screen readers.
 */
@mixin visually-hidden() {
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(100%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

/**
 * Reverse the properties applied by @mixin visually-hidden.
 * @param {String} $position - Positioning method for element.
 */
@mixin visually-shown($position: inherit) {
  clip: unset;
  clip-path: unset;
  height: unset;
  margin: 0;
  overflow: unset;
  position: $position !important;
  white-space: unset;
  width: unset;
}

/**
 * Fluid Typography Calculation
 * @param {String} $min-vw - Minimum wiewport width of fluid size.
 * @param {String} $man-vw - Maxmimum wiewport width of fluid size.
 * @param {String} $min-value - Minimum size.
 * @param {String} $max-value - Maxmimum size.
 */
@mixin fluid-type($min-vw, $max-vw, $min-value, $max-value) {
  $factor: 1 / ($max-vw - $min-vw) * ($max-value - $min-value);
  $calc-value: unquote(
    '#{ $min-value - ($min-vw * $factor) } + #{ 100vw * $factor }'
  );

  font-size: clamp(
    #{if($min-value > $max-value, $max-value, $min-value)},
    #{$calc-value},
    #{if($min-value > $max-value, $min-value, $max-value)}
  );
}

/**
 * Aspect ratio mixin.
 * Better to apply to HTML element that has one child element (or no child elements at all).
 * Example:
 *   HTML: 
 *     <div className="imageWrapper">
 *       <img .... />
 *     </div>
 *
 *   CSS:
 *      .imageWrapper {
 *        @include aspect-ratio($force: true);
 *        --ratio-percent: 100%;
 *      }
 *
 * @param {boolean} $force - force aspect ratio on all child types.
 */
@mixin aspect-ratio($force) {
  position: relative;
  height: fit-content;

  &::before {
    float: left;
    padding-top: var(--ratio-percent, 100%);
    content: '';
  }

  &::after {
    display: block;
    content: '';
    clear: both;
  }

  @if $force {
    &#{&} > * {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  } @else {
    &#{&} > img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

/**
 * Base: Critical
 * -----------------------------------------------------------------------------
 * Base critical styles.
 *
 */
// stylelint-disable selector-max-id, selector-type-no-unknown

@import '../config/variables-css';
@import '../base/classes-critical';

/**
 * Define dynamic CSS variables (those updated by JS).
 */
:root {
  --viewport-height: 100%;
}

/**
 * Body styles.
 */
body {
  background-color: var(--color-background-white);
  color: var(--color-text-primary);
  overflow-x: hidden;
}

/**
 * Async component helpers.
 */
.critical-component-hide {
  display: none;
}

.critical-component-clear {
  @include transition(opacity);
  opacity: 0;
}

/**
 * Display helpers.
 */
.critical-hide {
  display: none;
}

.critical-clear {
  @include transition(opacity);
  opacity: 0;
}

overlay,
[v-cloak] {
  display: none;
}

.main-content .shopify-section {
  display: none;
}

.main-content .shopify-section:first-child {
  display: inherit;
}

.is-hidden,
.visually-hidden {
  @include visually-hidden;
}

/**
 * Additional text styles.
 */
a {
  color: var(--color-text-primary);
}

/**
 * App proxy
 */
.app-handler {
  @include transition(opacity);
  background-color: var(--color-background-white, rgb(255, 255, 255));
  height: 100vh;
  opacity: 0;
  position: absolute;
  width: 100vw;
  z-index: var(--layer-heightened);

  &.is-visible {
    opacity: 1;
  }
}

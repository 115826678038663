/**
 * Helper: Transitions
 * -----------------------------------------------------------------------------
 * Transition classes for Vue transition component.
 *
 */

/**
 * Fade.
 */
.fade-enter-active,
.fade-leave-active {
  @include transition(opacity);

  &.fade-enter-from,
  &.fade-leave-to {
    opacity: 0;
  }

  &.fade-enter-to,
  &.fade-leave-from {
    opacity: 1;
  }
}

/**
 * Fade up.
 */
.fade-up-enter-active,
.fade-up-leave-active {
  @include transition(opacity, translate);

  &.fade-up-enter-from,
  &.fade-up-leave-to {
    opacity: 0;
    translate: 0 var(--spacing-l);
  }

  &.fade-up-enter-to,
  &.fade-up-leave-from {
    opacity: 1;
    translate: 0;
  }
}

/**
 * Fade down.
 */
.fade-down-enter-active,
.fade-down-leave-active {
  @include transition(opacity, translate);

  &.fade-down-enter-from,
  &.fade-down-leave-to {
    opacity: 0;
    translate: 0 calc(var(--spacing-l) * -1);
  }

  &.fade-down-enter-to,
  &.fade-down-leave-from {
    opacity: 1;
    translate: 0;
  }
}

/**
 * Slide left (opens from right).
 */
.slide-left-enter-active,
.slide-left-leave-active {
  @include transition(translate);

  &.slide-left-enter-from,
  &.slide-left-leave-to {
    translate: 100%;
  }

  &.slide-left-enter-to,
  &.slide-left-leave-from {
    translate: 0;
  }

  /**
   * Transitions overrides for mobile-drawer modal type.
   */
  @include mq($until: l) {
    &[data-mobile-drawer] {
      &.slide-left-enter-from,
      &.slide-left-leave-to {
        translate: 0 100%;
      }

      &.slide-left-enter-to,
      &.slide-left-leave-from {
        translate: 0;
      }
    }
  }
}

/**
 * Slide right (opens from left).
 */
.slide-right-enter-active,
.slide-right-leave-active {
  @include transition(translate);

  &.slide-right-enter-from,
  &.slide-right-leave-to {
    translate: -100%;
  }

  &.slide-right-enter-to,
  &.slide-right-leave-from {
    translate: 0;
  }

  /**
   * Transitions overrides for mobile-drawer modal type.
   */
  @include mq($until: l) {
    &[data-mobile-drawer] {
      &.slide-right-enter-from,
      &.slide-right-leave-to {
        translate: 0 100%;
      }

      &.slide-right-enter-to,
      &.slide-right-leave-from {
        translate: 0;
      }
    }
  }
}

/**
 * Slide up (opens from bottom).
 */
.slide-up-enter-active,
.slide-up-leave-active {
  @include transition(translate);

  &.slide-up-enter-from,
  &.slide-up-leave-to {
    translate: 0 100%;
  }

  &.slide-up-enter-to,
  &.slide-up-leave-from {
    translate: 0;
  }
}

/**
 * Slide down (opens from top).
 */
.slide-down-enter-active,
.slide-down-leave-active {
  @include transition(translate);

  &.slide-down-enter-from,
  &.slide-down-leave-to {
    translate: 0 -100%;
  }

  &.slide-down-enter-to,
  &.slide-down-leave-from {
    translate: 0;
  }

  /**
   * Transitions overrides for mobile-drawer modal type.
   */
  @include mq($until: l) {
    &[data-mobile-drawer] {
      &.slide-down-enter-from,
      &.slide-down-leave-to {
        translate: 0 100%;
      }

      &.slide-down-enter-to,
      &.slide-down-leave-from {
        translate: 0;
      }
    }
  }
}

/**
 * Component: Checkbox & radio
 * -----------------------------------------------------------------------------
 * Overrides browser styles of checkbox and radio input elements.
 *
 */
// stylelint-disable max-nesting-depth

.checkbox,
.radio {
  align-items: center;
  display: flex;

  &__input {
    @include visually-hidden;

    /**
     * Disabled state.
     */
    &[disabled] {
      + .checkbox__label,
      + .radio__label {
        color: var(--color-text-light-mid-grey);
        pointer-events: none;

        &::before {
          background-color: var(--color-background-grey);
          border-color: var(--color-background-grey);
        }
      }
    }

    /**
     * Focus state.
     */
    .is-tabbable & {
      &:focus + .checkbox__label,
      &:focus + .radio__label {
        @include focus-ring;
      }
    }
  }

  &__label {
    @include text-body-small;

    cursor: pointer;
    margin: 0;
    position: relative;

    &::before,
    &::after {
      box-sizing: border-box;
      content: '';
      position: absolute;
    }

    /**
     * Pseudo input styling.
     */
    &::before {
      background-color: var(--color-background-light);
      border: 1px solid var(--color-input-field-grey);
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    &::after {
      pointer-events: none;
    }

    /**
     * Linked label styling.
     */
    a {
      color: var(--color-primary);

      &:hover,
      &:focus {
        text-decoration: none;
      }
    }
  }
}

/**
 * Checkbox-specific styles.
 */
.checkbox {
  $parent: &;

  &__label {
    min-height: var(--form-checkbox);
    padding-inline-start: calc(var(--form-checkbox) + var(--spacing-xs));

    /**
     * Pseudo input styling.
     */
    &::before {
      @include transition(
        background-color var(--timing-quick),
        border-color var(--timing-quick) outline-color var(--timing-quick)
      );
      border: none;
      outline: 1px solid var(--color-text-light-mid-grey);
      height: var(--form-checkbox);
      width: var(--form-checkbox);
    }

    /**
     * Tick styling.
     */
    &::after {
      @include transition(scale var(--timing-quick));
      background: none;
      border-bottom: 2px solid var(--color-background-light);
      border-left: 2px solid var(--color-background-light);
      box-sizing: border-box;
      content: '';
      height: 4px;
      left: 4px;
      position: absolute;
      rotate: -45deg;
      scale: 0;
      top: 45%;
      transform: translateY(-50%);
      width: 10px;
    }
  }

  /**
   * Active state.
   */
  &__input:checked {
    + #{$parent}__label {
      &::before {
        background-color: var(--color-black);
        border-color: var(--color-background-light);
        outline-color: var(--color-border-dark);
      }

      &::after {
        opacity: 1;
        scale: 1;
      }
    }
  }

  /**
   * Required asterisk color
   */
  &__required {
    color: var(--color-support-error);
  }
}

/**
 * Radio specific styles.
 */
.radio {
  $parent: &;

  &__label {
    min-height: var(--form-radio-outer);
    padding-inline-start: calc(var(--form-radio-outer) + var(--spacing-xs));

    /**
     * Pseudo input styling.
     */
    &::before {
      @include transition(border-color var(--timing-quick));
      border-radius: 100%;
      height: var(--form-radio-outer);
      width: var(--form-radio-outer);
    }

    /**
     * Circle styling.
     */
    &::after {
      @include transition(scale var(--timing-quick));
      background-color: var(--color-black);
      border-radius: 100%;
      content: '';
      height: var(--form-radio-inner);
      left: calc((var(--form-radio-outer) - var(--form-radio-inner)) / 2);
      position: absolute;
      scale: 0;
      top: 50%;
      width: var(--form-radio-inner);
      transform: translateY(-50%);
    }
  }

  /**
   * Active state.
   */
  &__input:checked {
    + #{$parent}__label {
      &::before {
        border-color: var(--color-black);
      }

      &::after {
        scale: 1;
      }
    }
  }
}

/**
 * Base: Reset
 * -----------------------------------------------------------------------------
 * https://piccalil.li/blog/a-modern-css-reset/
 *
 */
// stylelint-disable declaration-no-important, no-duplicate-selectors, time-min-milliseconds

/*
 * Box sizing rules.
 */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/*
 * Remove default margin.
 */
body,
h1,
h2,
h3,
h4,
p,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

/*
 * Remove default styles.
 */
legend {
  display: table;
  float: left;
  margin: 0;

  + * {
    clear: both;
  }
}

/*
 * Remove list styles on ul, ol elements.
 * - Re-added in .rte class elements.
 */
ul,
ol {
  list-style: none;
  padding: 0;
}

/*
 * Set core root defaults.
 */
html:focus-within {
  scroll-behavior: smooth;
}

/*
 * Set core body defaults.
 */
body {
  line-height: 1.5;
  min-height: 100vh;
  text-rendering: optimizespeed;
}

/*
 * A elements that don't have a class get default styles.
 */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/*
 * Make images easier to work with.
 */
img,
picture {
  display: block;
  max-width: 100%;
}

/*
 * Inherit fonts for inputs and buttons.
 */
input,
button,
textarea,
select {
  font: inherit;
}

/*
 * Remove all animations and transitions for people that prefer not to see them.
 */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    scroll-behavior: auto !important;
    transition-duration: 0.01ms !important;
  }
}

/**
 * Base: Accessibility
 * -----------------------------------------------------------------------------
 * Handles tabbable state styles and sign post styles.
 *
 */
.accessibility {
  &__sign-post.button {
    left: var(--spacing-s);
    max-width: calc(100vw - (var(--spacing-s) * 2));
    position: fixed;
    top: var(--spacing-s);
    z-index: var(--layer-temporary);

    &:not(:focus) {
      @include visually-hidden;
    }
  }

  &__information.is-active {
    line-height: var(--line-height-normal);
  }

  /**
   * Media queries.
   */
  @include mq($from: l) {
    &__information.is-active:focus {
      max-width: 200px;
    }
  }
}

/**
 * Add scroll margin top to sign post elements.
 */
[js-sign-post] {
  scroll-margin-block-start: var(--header-visible-height);
}

/**
 * Reset focus state on tabbable elements.
 */
[tabindex],
[draggable],
a[href],
area,
button:enabled,
input:not([type='hidden']):enabled,
object,
select:enabled,
textarea:enabled {
  &:focus {
    outline: 0;
  }

  .is-tabbable &:focus {
    @include focus-ring;
  }
}

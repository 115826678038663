/**
 * Base: Icons
 * -----------------------------------------------------------------------------
 * Base styling for all theme icons.
 *
 */
.icon {
  display: inline-block;
  fill: currentcolor;
  height: var(--icon-m);
  vertical-align: middle;
  width: var(--icon-m);

  &__loading {
    animation: spin var(--timing-slow) linear infinite;
  }

  .no-svg & {
    display: none;
  }

  /**
   * High contrast and forced colour mode.
   */
  @media (forced-colors: active) {
    fill: canvastext;

    a &,
    button & {
      fill: linktext;
    }
  }
}

/**
 * Use for buttons with only icon content.
 * - Makes for a larger touch target.
 */
.icon-button {
  @include button-reset;
  line-height: var(--line-height-baseline, 100%);
  margin: calc(var(--spacing-xs) * -1);
  padding: var(--spacing-xs);
}

svg,
symbol {
  circle,
  ellipse,
  g,
  line,
  path,
  polygon,
  polyline,
  img {
    max-width: 100%;
  }

  rect {
    fill: inherit;
    stroke: inherit;
  }
}

.payment-icons {
  cursor: default;
  user-select: none;
}

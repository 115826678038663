/**
 * Base: Keyframes
 * -----------------------------------------------------------------------------
 * Keyframes used for animated CSS.
 *
 */
@keyframes pulse {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes spin {
  from {
    rotate: 0;
  }

  to {
    rotate: 1turn;
  }
}

/**
 * Component: Form group
 * -----------------------------------------------------------------------------
 * Group styling for a combination of form elements within a form.
 *
 */
.form-group {
  $parent: &;

  + #{$parent} {
    margin-block-start: var(--spacing-l);
  }

  &__field {
    align-items: baseline;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;

    &:not(:last-child) {
      margin-block-end: var(--spacing-l);
    }
  }

  &__label {
    @include transition(transform var(--timing-quick));
    @include text-body-2-mobile;
    color: var(--color-input-field-grey);
    inset: var(--spacing-s);
    margin: 0;
    pointer-events: none;
    position: absolute;
    transform-origin: top left;

    abbr {
      text-decoration: none;
    }

    &--required::after {
      content: ' *';
      color: var(--color-support-error);
    }
  }

  input[type='email'],
  input[type='number'],
  input[type='password'],
  input[type='search'],
  input[type='tel'],
  input[type='text'],
  input[type='date'],
  textarea,
  select {
    border: 1px solid var(--color-border-input-field);
    color: var(--color-text-primary);
    // Padding + line height + border
    // TODO: Update line height calculation
    min-height: calc((var(--spacing-s) * 2) + var(--font-size-m) + 2px);
    padding: var(--spacing-s);
    width: 100%;

    &:focus {
      border-color: var(--color-support-focus, rgb(39, 110, 241));
    }

    &.input-error {
      border-color: var(--color-support-error, rgb(171, 19, 0));
      color: var(--color-support-error);
    }

    &.input-error + #{$parent}__label {
      color: var(--color-support-error);
    }
  }

  select {
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.59 8.59003L12 13.17L7.41 8.59003L6 10L12 16L18 10L16.59 8.59003Z' fill='currentColor'/%3E%3C/svg%3E%0A");
    background-position: 50% right 10px;
    background-repeat: no-repeat;
    background-size: 20px;
  }

  &__footer {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    margin-block-start: var(--spacing-l);
  }

  /**
   * Floating labels state logic
   */
  input[type='email'],
  input[type='number'],
  input[type='password'],
  input[type='search'],
  input[type='tel'],
  input[type='text'],
  textarea {
    &::placeholder {
      color: transparent;
    }

    &:not(:placeholder-shown) {
      padding: calc(var(--spacing-m) + var(--spacing-2xs)) var(--spacing-s)
        var(--spacing-2xs);
    }

    &:not(:placeholder-shown) + #{$parent}__label {
      transform: translateY(calc(var(--spacing-xs) * -1)) scale(0.7);
      // If label is in multiline truncate it with ellipsis.
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &::after {
        content: none;
      }
    }
  }

  input[type='date'] {
    color: transparent;

    &.input-error:not([data-valid]):not(:focus-visible) {
      color: transparent;
    }

    &:focus-visible,
    &[data-valid] {
      padding: calc(var(--spacing-m) + var(--spacing-2xs)) var(--spacing-s)
        var(--spacing-2xs);
    }

    &:focus-visible:not(.input-error),
    &[data-valid]:not(.input-error) {
      color: initial;
    }

    &:focus-visible + #{$parent}__label,
    &[data-valid] + #{$parent}__label {
      transform: translateY(calc(var(--spacing-xs) * -1)) scale(0.7);

      &::after {
        content: none;
      }
    }
  }

  select {
    &:valid,
    &:disabled {
      padding: calc(var(--spacing-m) + var(--spacing-2xs)) var(--spacing-s)
        var(--spacing-2xs);
      color: initial;
    }

    &:required:invalid:focus-visible + #{$parent}__label,
    &:valid + #{$parent}__label,
    &:disabled + #{$parent}__label {
      transform: translateY(calc(var(--spacing-xs) * -1)) scale(0.7);

      &::after {
        content: none;
      }
    }

    &:invalid {
      color: var(--color-input-field-grey);
    }
  }

  /**
   * Password Field Styles
   */
  &__password-wrapper {
    display: flex;
    position: relative;
    width: 100%;

    &:focus-within {
      .form-group__password-toggle {
        border-color: var(--color-support-focus);
      }
    }

    &#{&} input {
      border-right: 0;
    }
  }

  &__password-toggle {
    @include button-reset;
    border: 1px solid var(--color-border-input-field);
    border-left: 0;
    padding: var(--spacing-xs) var(--spacing-m);

    &.input-error {
      border-color: var(--color-support-error);
    }
  }

  &__password-toggle-text {
    display: block;
  }

  /**
   * Modifiers.
   */
  &.is-hidden {
    display: none;
  }

  @include mq($from: m) {
    &__label {
      @include text-body-2;
      padding-block-start: var(--spacing-3xs);
    }
  }
}

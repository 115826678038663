/**
 * Helper: RTE
 * -----------------------------------------------------------------------------
 * Styles for rich text editor content.
 *
 */
// stylelint-disable unit-disallowed-list
/**
 * Helper: RTE
 * -----------------------------------------------------------------------------
 * Styles for rich text editor content.
 *
 */
// stylelint-disable

@mixin elements() {
  + h1,
  + h2,
  + h3,
  + h4,
  + h5,
  + h6 {
    margin-block-start: var(--spacing-l);
  }

  + ul,
  + ol,
  + table,
  + blockquote {
    margin-block-start: var(--spacing-m);
  }

  + p {
    margin-block-start: 0;
    padding-block-start: var(--spacing-l);
  }
}

.rte {
  h1,
  h2,
  h3 {
    margin-block-start: var(--spacing-xs);
    margin-block-end: var(--spacing-l);
    color: var(--color-text-dark);

    + p,
    + ul,
    + ol,
    + table {
      margin-block-start: var(--spacing-m);
    }
  }

  h4,
  h5,
  h6 {
    color: var(--color-text-dark);
    font-family: var(--font-family-sans) !important;
    margin-block-end: var(--spacing-l);
    margin-block-start: 0;
  }

  h1 {
    @include text-heading-h1-mobile;
  }

  h2 {
    @include text-heading-h2-mobile;
  }

  h3 {
    @include text-heading-h3-mobile;
  }

  h4 {
    @include text-heading-h4-mobile;
  }

  h5 {
    @include text-heading-h5-mobile;
  }

  h6 {
    @include text-heading-h6-mobile;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    b,
    i,
    u {
      font-size: inherit;
    }
  }

  p,
  ul,
  ol,
  table,
  blockquote {
    @include elements;
    @include defaults-body;
  }

  a {
    background: none;
    text-decoration: underline;
    text-underline-offset: var(--spacing-2xs);
  }

  u {
    background: none;
    text-decoration: underline;
    text-underline-offset: var(--spacing-2xs);
  }

  p {
    @include text-body-1-mobile;
    color: var(--color-text-dark);
    white-space: pre-line;

    a {
      @include text-body-1-mobile;
      background: none;
      color: var(--color-text-dark);
      text-decoration: underline;
      text-underline-offset: var(--spacing-2xs);
    }
  }

  b {
    @include text-body-1-bold-mobile;
  }

  /**
   * Resetting list styles to browser default.
   */
  ul,
  ol {
    list-style: unset;
    margin-block-end: 0;
    margin-block-start: 0;
    // Using em so the margin is proportional to font size
    margin-inline-end: 1em;
    margin-inline-start: 1em;
    padding-inline-start: 1em;
    list-style-position: inside;
    p {
      display: inline-block;
    }
  }

  ol {
    list-style: decimal;
    list-style-position: inside;
  }

  blockquote {
    @include text-body-1-mobile;
    color: var(--color-text-dark);
    margin-block-end: var(--spacing-xl) !important;
    margin-block-start: var(--spacing-xl) !important;
    padding: var(--spacing-2xl);
    border-left: 5px solid var(--color-light-grey);
  }

  &.rightBlockquote blockquote {
    border-left: none;
    border-right: 5px solid var(--color-light-grey);
  }

  hr {
    border-color: var(--color-text-dark);
    width: 100%;
    border-style: solid;
  }

  table {
    border: 1px solid var(--color-text-dark);
    border-collapse: collapse;

    th,
    td {
      border: 1px solid var(--color-text-dark);
      padding: var(--spacing-xs);
    }

    th {
      background: var(--color-light-grey);
      font-weight: 700;
      p {
        font-weight: 700;
      }
    }
  }

  @include mq($until: l) {
    gap: 0 !important;
  }

  @include mq($from: m) {
    h1 {
      @include text-heading-h1;
    }

    h2 {
      @include text-heading-h2;
    }

    h3 {
      @include text-heading-h3;
    }

    h4 {
      @include text-heading-h4;
    }

    h5 {
      @include text-heading-h5;
    }

    h6 {
      @include text-heading-h6;
    }

    blockquote {
      @include text-body-1;
      margin-block-end: var(--spacing-2xl);
      margin-block-start: var(--spacing-2xl);
    }

    p {
      @include text-body-1;

      a {
        @include text-body-1;
        text-decoration: underline;
        text-underline-offset: var(--spacing-2xs);
      }
    }

    b {
      @include text-body-1-bold;
    }
  }
}

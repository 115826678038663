/**
 * Base: Classes
 * -----------------------------------------------------------------------------
 * Automatically generated by `design` command, do not edit.
 *
 */
// stylelint-disable

// Typography
html,
body {
  @include defaults-body;
  font-size: var(--scale-base);
}
